// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-error-tsx": () => import("./../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-templates-author-tsx": () => import("./../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-category-tsx": () => import("./../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-dynpages-authors-tsx": () => import("./../src/templates/dynpages/authors.tsx" /* webpackChunkName: "component---src-templates-dynpages-authors-tsx" */),
  "component---src-templates-dynpages-index-tsx": () => import("./../src/templates/dynpages/index.tsx" /* webpackChunkName: "component---src-templates-dynpages-index-tsx" */),
  "component---src-templates-dynpages-search-tsx": () => import("./../src/templates/dynpages/search.tsx" /* webpackChunkName: "component---src-templates-dynpages-search-tsx" */),
  "component---src-templates-list-tsx": () => import("./../src/templates/list.tsx" /* webpackChunkName: "component---src-templates-list-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

