/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// You can delete this file if you're not using it
// const React = require("react")

import React from "react";

import { IntlProvider } from 'react-intl'
const messages = require(`./src/i18n/${process.env.GATSBY_LOCALE}.json`)

export const wrapRootElement = ({ element }) => {
    return (<IntlProvider locale={process.env.GATSBY_LOCALE || "hu"} messages={messages}>
        {element}
    </IntlProvider>)
}
